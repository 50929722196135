
.basketInfo{
    float:right;
    margin-right: 100px;
}

.basketInfo button{
    display: block;
    margin: 10px auto;
}

.basketProducts{
    margin:10px auto;
    width:50%;
}
