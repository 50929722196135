*{
    box-sizing: border-box;
}

.header{
    border-bottom: thin solid rgb(80, 80, 85);
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.heading{
    font-family: 'Helvetica';
    font-size: 40px;
    display: block;
    text-align: center;
}

nav{
    display: flex;
    justify-content: space-around;
}

.iconWithLabel span{
    display: block;
    text-align: center;
}

.iconWithLabel .icon{
    display: block;
}

.icon{
    width:50px;
    height:50px;
    margin-top:25px;
    color:rgb(80, 80, 85);
}

/*dropdown */

.dropdownContent {
    background-color: rgb(80, 80, 85);
    display: none;
    position: absolute;
    min-width: 160px;
    z-index: 1;
}

.dropdownLink {
    text-align: center;
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown:hover .dropdownContent {display: block;}


