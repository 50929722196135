.ordersDiv{
    margin:10px auto;
    width:90%;
}
.orders{
    display: inline-block;
    width: 60%;
    vertical-align: top;
    margin: 0 10px;
}

.orderDetails{
    display: inline-block;
}

.ordersTable{
    width:100%;
    border: thin solid black;
    border-collapse: collapse;
    text-align: center;
}
.ordersTable td,th{
    line-height: 30px;
    border: thin solid black;
}

.highlightedOrder{
    background-color: yellow;
}

.productImg{
    display: inline-block;
    border: thin solid black;
    vertical-align: middle;
    width:30px;
    height:30px;
}

.productInfo{
    display: inline-block;
    margin: 5px 5px;
}
