.authDiv {
    width:50%;
    margin:20px auto;
    padding:10px;
}

.authDiv h2{
    font-size: 32px;
}

.authForm input{
    display: block;
    height: 30px;
}

.dataInput{
    width: 70%;
    margin: 10px auto;
}

.smallDataDiv{
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: 10px auto;
}

.smallDataInput{
    width: 49%;
}

.submitBtn {
    background-color: rgb(80, 80, 85);
    color:white;
    border-radius: 25px;
    width:50%;
    font-size: 16px;
    margin: 25px auto;
}
.submitBtn:hover{
    background-color: rgb(80, 80, 85,0.9);
}

.authLink {
    margin: 5px 50px;
}

.oauthLoginDiv{
    margin: 0 auto;
    width: 250px;
    text-align: center;
}