.basketProduct{
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    border:thin solid black;
    margin-bottom: 10px;
    padding: 5px;
}

.productImg{
    width:100px;
    height:100px;
    border: thin solid black;

}

.productOptionBtn{
    display: block;
    margin: 10px auto 10px;
    width:150px;
}

.productQtyDiv{
    display: block;
    margin: 0 auto;
    width:300px
}

.productQtyInput{
    margin: 0 5px;
}