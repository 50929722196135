.product{
    width:250px;
    height:300px;
    margin:10px 10px;
    border: thin solid black;
}

.productImg{
    margin:10px auto;
    width: 200px;
    height: 200px;
    border: thin dotted black;
}

.productName{
    text-align: center;
    margin: 10px 0px;
}

.productPrice{
    font-size: 18px;
    margin: 0px 25px;
}



