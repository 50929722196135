.productsDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.selectCategory{
    margin: 20px 10px;
    display: inline-block;
    width:200px;
}