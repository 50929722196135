
.product{
    width: 60%;
    height:400px;
    margin: 10px auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.productImg{
    border: medium solid black;
    width:100%;
    justify-self: center;
}

.productInfoDiv{
    text-align: center;
}

.productMsg{
    color:green;
}

.productQty{
    margin: 0 5px;
}

.productNotInBasketDiv{
    margin-top: 10px;
}
.productInBasketDiv{
    margin-top: 10px;
}
.productInBasketDiv button{
    margin: 0px 1px;
}
